import { IntegrationEvent, MessageTopics } from "../../types";
import { debugLog, pretty } from "../../utils";

interface Message {
  topic: MessageTopics;
  context: IntegrationEvent;
  handshakeRef?: string;
}
class ParentCommunication {
  debug = false;
  origin?: string | null;
  handshakeRef: string | null;
  iframe: HTMLIFrameElement;
  iframeWindow?: Window | null;
  handshakeSuccess = false;
  listeners: Array<(message: Message) => void> = [];

  private _messageQueue: Array<Message> = [];
  private _onIframeLoad?: () => void;
  private _latestHandshakeTimeout?: NodeJS.Timeout;

  constructor(src: string | null, targetElement: HTMLElement, debug?: boolean) {
    this.debug = !!debug;
    this.iframe = document.createElement("iframe");
    targetElement.appendChild(this.iframe);

    this.handshakeRef = Date.now().toString(8);

    this.resetIframe(src);
    window.addEventListener("message", (event: MessageEvent<Message>) => {
      debugLog(this.debug)(
        `ParentCommunication: received message from child window, ${pretty(
          event.data
        )}, handshakeSuccess ${this.handshakeSuccess}, handshakeRef: ${
          this.handshakeRef
        }`
      );

      const message = event.data;
      if (message.handshakeRef !== this.handshakeRef) return;

      if (message.topic === MessageTopics.HANDSHAKE_REPLY) {
        this.handshakeSuccess = true;
        this.flushQueue();
      } else {
        this.sendMessageToParent(message);
      }
    });
  }

  resetIframe(src: string | null) {
    this.iframe.style.display = src === null ? "none" : "block";

    const nothingToDo = src === this.iframe.src;
    if (nothingToDo) return;

    // CLEANING UP
    this.handshakeSuccess = false;

    if (this._onIframeLoad) {
      this.iframe.removeEventListener("load", this._onIframeLoad);
    }

    if (this._latestHandshakeTimeout) {
      clearTimeout(this._latestHandshakeTimeout);
    }

    // UPDATING IFRAME TARGET
    if (!src) {
      this.iframe.removeAttribute("src");
      this.iframeWindow = null;

      return;
    } else {
      this.origin = src && new URL(src).origin;
      this.iframe.setAttribute("src", src ?? "");
      this.iframe.setAttribute(
        "id",
        `imgarena-event-centre__${this.handshakeRef}`
      );
      this.iframeWindow = this.iframe.contentWindow;

      this._onIframeLoad = () => {
        this.triggerHandshake(TEST_ENV ? 2 : 20);
      };

      this.iframe.addEventListener("load", this._onIframeLoad);
    }
  }

  triggerHandshake(attemptsLeft: number) {
    if (this.handshakeSuccess) return;

    if (attemptsLeft === 0) {
      this.sendMessageToParent({
        topic: MessageTopics.HANDSHAKE_FAILED,
        context: {},
      });
    } else {
      if (this.iframeWindow) {
        debugLog(this.debug)(
          `ParentCommunication: triggering handshake, attempts left: ${attemptsLeft}, handshakeRef: ${this.handshakeRef}`
        );

        this.iframeWindow.postMessage(
          {
            topic: MessageTopics.HANDSHAKE_ATTEMPT,
            context: {
              frameOrigin: this.origin,
              handshakeRef: this.handshakeRef, // for legacy compatibility
              versionNumber: FRSJS_VERSION,
            },
            handshakeRef: this.handshakeRef,
          },
          "*"
        );
      }

      this._latestHandshakeTimeout = setTimeout(
        () => {
          this.triggerHandshake(attemptsLeft - 1);
        },
        TEST_ENV ? 0 : 150
      ); // TODO: progressive backoff
    }
  }

  flushQueue() {
    if (this._messageQueue.length === 0) return;

    debugLog(this.debug)(
      `ParentCommunication: flushing queue, handshakeRef: ${this.handshakeRef}, queue length: ${this._messageQueue.length}`
    );

    this._messageQueue.forEach((message) => {
      this.sendMessageToIframe(message);
    });
    this._messageQueue = [];
  }

  sendMessageToIframe(message: Message) {
    debugLog(this.debug)(
      `ParentCommunication: Sending message to iframe: Message: ${pretty(
        message
      )}, handshakeSuccess: ${this.handshakeSuccess}, handshakeRef: ${
        this.handshakeRef
      }`
    );

    if (!this.handshakeSuccess) {
      this._messageQueue.push(message);
    } else {
      if (this.iframeWindow && this.origin) {
        this.iframeWindow.postMessage(
          { ...message, handshakeRef: this.handshakeRef },
          this.origin
        );
      }
    }
  }

  sendMessageToParent(message: Message) {
    this.listeners.forEach((listener) => {
      listener(message);
    });
  }

  onMessage(callback: (message: Message) => void) {
    this.listeners.push(callback);
  }
}

export { ParentCommunication };
