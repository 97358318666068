export function encodeJSONParameter(
  input?: object,
  enableURLEncoding = false
): string {
  if (!input) return "";

  if (enableURLEncoding) {
    return window.btoa(encodeURIComponent(JSON.stringify(input)));
  }

  return window.btoa(JSON.stringify(input));
}
