import { EventCentreConstructorArgs } from "../../types";
import { encodeJSONParameter } from "../../utils";
import { defaultEventCentreConstructionArgs } from "../../constants";
import { IMGARENA_PROD_DOMAIN } from "../createEventCentreUrl";

export function createMwSoccerUrl(
  partialArgs: EventCentreConstructorArgs
): string | null {
  if (partialArgs.sport !== "mw-soccer") {
    throw new Error(
      `${createMwSoccerUrl.name} must be called with sport = 'mw-soccer'`
    );
  }

  if (!partialArgs.initialContext) {
    throw new Error(`Missing required initial context for sport 'mw-soccer'`);
  }

  const args = Object.assign(
    {},
    defaultEventCentreConstructionArgs,
    partialArgs
  );

  let baseUrl = `https://${args.operator}.${IMGARENA_PROD_DOMAIN}/${args.sport}/${args.version}/${args.targetModule}/`;

  if (args.__iframe_url_override__) {
    baseUrl =
      args.__iframe_url_override__.origin + args.__iframe_url_override__.path;
  }

  const iframeUrl = new URL(baseUrl);
  const params = new URLSearchParams(iframeUrl.search);

  (["operator", "operatorEnv", "env"] as const).forEach((property) => {
    const value = args[property];

    if (value) {
      params.append(property, value.toString());
    }
  });

  (["initialContext", "options"] as const).forEach((property) => {
    if (args[property]) {
      const encodedValue = encodeJSONParameter(args[property]);

      params.append(property, encodedValue);
    }
  });

  iframeUrl.search = params.toString();

  return iframeUrl.toString();
}
