import { EventCentreConstructorArgs } from "../types";
import { encodeJSONParameter } from "../utils";
import { defaultEventCentreConstructionArgs } from "../constants";

import { createMwSoccerUrl } from "./utils/createMwSoccerUrl";

// eslint-disable-next-line @typescript-eslint/naming-convention
export const IMGARENA_PROD_DOMAIN = "apps.imgarena.com";

export function createEventCentreUrl(
  partialArgs: EventCentreConstructorArgs
): string | null {
  if (partialArgs.sport === "mw-soccer") {
    return createMwSoccerUrl(partialArgs);
  }

  const args = Object.assign(
    {},
    defaultEventCentreConstructionArgs,
    partialArgs
  );

  if (!partialArgs.eventId) {
    return null;
  }

  let baseUrl = `https://${args.operator}.${IMGARENA_PROD_DOMAIN}/${args.sport}/${args.version}/${args.targetModule}/`;

  if (args.__iframe_url_override__) {
    baseUrl =
      args.__iframe_url_override__.origin + args.__iframe_url_override__.path;
  }

  const iframeUrl = new URL(baseUrl);
  const params = new URLSearchParams(iframeUrl.search);

  (
    [
      "eventId",
      "operator",
      "operatorEnv",
      "theme",
      "language",
      "env",
      "targetModule",
      "trading",
    ] as const
  ).forEach((property) => {
    const value = args[property];

    if (value) {
      params.append(property, value.toString());
    }
  });

  if (args.__replay_id__) {
    params.append("replayId", args.__replay_id__);
  }

  (["initialContext", "options"] as const).forEach((property) => {
    if (args[property]) {
      const encodedValue = encodeJSONParameter(args[property]);

      params.append(property, encodedValue);
    }
  });

  iframeUrl.search = params.toString();

  return iframeUrl.toString();
}
