import { isValidEventCentreConstructorOptions } from "../../utils";
import { EventCentreConstructorArgs } from "../../types";
import { createBetlinkUrl, createEventCentreUrl } from "../../domain";

import { IframeContainer } from "./IframeContainer";

class EventCentreIframe extends IframeContainer {
  constructor(args: EventCentreConstructorArgs) {
    isValidEventCentreConstructorOptions(args);

    super({
      __debug__: !!args.__debug__,
      targetElementSelector: args.targetElementSelector || "",
    });

    if (args.betlinkVersion) {
      this.initCommunication(createBetlinkUrl(args), "betlink");
    } else {
      this.initCommunication(createEventCentreUrl(args) || "", "event-centre");
    }
  }
}

export default function eventCentre(
  options: EventCentreConstructorArgs
): EventCentreIframe {
  return new EventCentreIframe(options);
}
