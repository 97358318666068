import { IntegrationEvent, MessageTopics, HandshakeMessage } from "../../types";
import { debugLog, pretty } from "../../utils";

interface Message {
  topic: MessageTopics;
  context: IntegrationEvent;
  handshakeRef?: string;
}

class ChildCommunication {
  debug = false;
  handshakeSuccess = false;
  handshakeRef?: string;
  origin?: string;
  listeners: Array<(message: Message) => void> = [];
  parentWindow: Window;
  private _messageQueue: Array<Message> = [];
  private resolveVersionNumber?: (versionNumber?: string) => void;

  constructor(debug?: boolean) {
    this.debug = !!debug;
    this.parentWindow = window.parent;

    window.addEventListener("message", (event: MessageEvent<Message>) => {
      const message = event.data;

      debugLog(this.debug)(
        `ChildCommunication: received message from parent window, message: ${pretty(
          message
        )}, handshakeSuccess: ${this.handshakeSuccess}, handshakeRef: ${
          this.handshakeRef
        }`
      );

      if (message.topic === MessageTopics.HANDSHAKE_ATTEMPT) {
        this.handshakeSuccess = true;
        this.origin = event.origin;
        this.handshakeRef = message.handshakeRef;

        if (
          isHandshakeEvent(message.context) &&
          message.context?.versionNumber
        ) {
          this.resolveVersionNumber?.(message.context?.versionNumber);
        }

        debugLog(this.debug)(
          `ChildCommunication: handshake request received, handshakeRef: ${this.handshakeRef}`
        );

        this.sendMessageToParent({
          topic: MessageTopics.HANDSHAKE_REPLY,
          context: {},
        });
        this.flushQueue();
      } else {
        this.sendMessageToEventCentre(message);
      }
    });
  }

  flushQueue() {
    this._messageQueue.forEach((message) => {
      this.sendMessageToParent(message);
    });
    this._messageQueue = [];
  }

  sendMessageToParent(message: Message) {
    debugLog(this.debug)(
      `ChildCommunication: attempting to send message to parent, message: ${pretty(
        message
      )}, handshakeSuccess: ${this.handshakeSuccess}, handshakeRef: ${
        this.handshakeRef
      }`
    );

    if (!this.handshakeSuccess) {
      this._messageQueue.push(message);
    } else {
      this.parentWindow.postMessage(
        { ...message, handshakeRef: this.handshakeRef },
        "*"
      );
    }
  }

  sendMessageToEventCentre(message: Message) {
    this.listeners.forEach((listener) => {
      listener(message);
    });
  }

  onMessage(callback: (message: Message) => void) {
    this.listeners.push(callback);
  }

  // when not in an iframe this will return eg: `no-iframe`
  // when legacy frs.js is used this will return empty string eg: `legacy`
  // when it takes longer than 5 seconds (handshakes failing, or iframe without frs.js) it will return `no-frsjs-or-timeout`
  // when everything works it will return a version number eg: `1.33.0`
  async getVersionNumber(): Promise<string | undefined> {
    // there is no host version number when the current page is not in an iframe
    if (window.parent === window) {
      return Promise.resolve("no-iframe");
    }

    // return a promise which resolves only after the handshake completes, this can take a few attempts
    // it resolves to 'no-frsjs-or-timeout' after 5000ms in case of any handshake issues or if the parent page doesn't use frs.js
    return new Promise((resolve) => {
      this.resolveVersionNumber = (hostVersionNumber?: string) =>
        resolve(hostVersionNumber || "legacy");

      setTimeout(() => resolve("no-frsjs-or-timeout"), 5000);
    });
  }
}

function isHandshakeEvent(event: any): event is HandshakeMessage {
  return event?.versionNumber;
}

export { ChildCommunication };
