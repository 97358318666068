import { EventCentreConstructorArgs, MessageTopics } from "../types";

/* eslint-disable-next-line complexity,max-lines-per-function */
export function isValidEventCentreConstructorOptions(
  args?: EventCentreConstructorArgs
): boolean | TypeError {
  if (!args || !Object.keys(args).length) {
    throw TypeError(`Expected options object to be passed, received: ${args}`);
  }

  if (args?.sport === "mw-soccer") {
    return isValidMWSoccerEventCentreConstructorOptions(args);
  }

  const {
    operator,
    sport,
    version,
    betlinkVersion,
    eventId,
    theme,
    language,
    targetModule,
    targetElementSelector,
    initialContext,
    trading,
    options,
  } = args;

  if (!operator || typeof operator !== "string") {
    throw TypeError(
      `Expected operator to be non null string, received: ${typeof operator} ${operator}`
    );
  }

  if (!sport || typeof sport !== "string") {
    throw TypeError(
      `Expected sport to be non null string, received: ${typeof sport} ${sport}`
    );
  }

  if (!version || typeof version !== "string") {
    throw TypeError(
      `Expected version to be non null string, received: ${typeof version} ${version}`
    );
  }

  if (betlinkVersion && typeof betlinkVersion !== "string") {
    throw TypeError(
      `Expected betlinkVersion to be non null string, received: ${typeof betlinkVersion} ${betlinkVersion}`
    );
  }

  if ((!eventId || typeof eventId !== "string") && !betlinkVersion) {
    throw TypeError(
      `Expected eventId to be non null string, received: ${typeof eventId} ${eventId}`
    );
  }

  if (theme && typeof theme !== "string") {
    throw TypeError(
      `Expected theme to be a string, received: ${typeof theme} ${theme}`
    );
  }

  if (language && typeof language !== "string") {
    throw TypeError(
      `Expected language to be a string, received: ${typeof language} ${language}`
    );
  }

  if ((!targetModule || typeof targetModule !== "string") && !betlinkVersion) {
    throw TypeError(
      `Expected targetModule to be non null string, received: ${typeof targetModule} ${targetModule}`
    );
  }

  if (
    !targetElementSelector ||
    (typeof targetElementSelector !== "string" &&
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      !((targetElementSelector as any) instanceof window.HTMLElement))
  ) {
    throw TypeError(
      `Expected targetElementSelector to be non null string or dom element, received: ${typeof targetElementSelector} ${targetElementSelector}`
    );
  }

  if (initialContext) {
    if (typeof initialContext !== "object") {
      throw TypeError(
        `Expected initialContext to be context update object, received: ${typeof initialContext} ${initialContext}`
      );
    }
  }

  if (options) {
    if (typeof options !== "object") {
      throw TypeError(
        `Expected options to be options object, received: ${typeof options} ${options}`
      );
    }
  }

  const isTradingValueSet = trading !== null && trading !== undefined;
  const isTradingABoolean = typeof trading === "boolean";

  if (isTradingValueSet && !isTradingABoolean) {
    throw TypeError(
      `Expected trading to be boolean or undefined, received: ${typeof trading} ${trading}`
    );
  }

  return true;
}

export function isValidMessageTopic(topic: MessageTopics): TypeError | boolean {
  if (!Object.values(MessageTopics).includes(topic)) {
    throw TypeError(
      `Expected supported message topic, received: ${typeof topic} ${topic}`
    );
  }

  return true;
}

// eslint-disable-next-line complexity
export function isValidMWSoccerEventCentreConstructorOptions(
  args?: EventCentreConstructorArgs
): boolean | TypeError {
  const { operator, sport, targetElementSelector, initialContext } = args || {};

  if (!initialContext || typeof initialContext !== "object") {
    throw TypeError(
      `Expected initialContext to be context update object, received: ${typeof initialContext} ${initialContext}`
    );
  }

  if (!operator || typeof operator !== "string") {
    throw TypeError(
      `Expected operator to be non null string, received: ${typeof operator} ${operator}`
    );
  }

  if (!sport || typeof sport !== "string") {
    throw TypeError(
      `Expected sport to be non null string, received: ${typeof sport} ${sport}`
    );
  }

  if (
    !targetElementSelector ||
    (typeof targetElementSelector !== "string" &&
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      !((targetElementSelector as any) instanceof window.HTMLElement))
  ) {
    throw TypeError(
      `Expected targetElementSelector to be non null string or dom element, received: ${typeof targetElementSelector} ${targetElementSelector}`
    );
  }

  return true;
}
