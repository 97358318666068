import { EventCentreLiteConstructorArgs } from "../../types";
import { encodeJSONParameter } from "../../utils";

import { IframeContainer } from "./IframeContainer";

type RequiredDefaultProperties = Required<
  Omit<
    EventCentreLiteConstructorArgs,
    | "sport"
    | "eventId"
    | "view"
    | "targetElementSelector"
    | "initialContext"
    | "__iframe_url_override__"
    | "__replay_id__"
    | "__debug__"
  >
>;

const defaultConstructorArgs: RequiredDefaultProperties = {
  theme: "default",
  targetModule: "",
  language: "en-GB",
  env: "prod",
  operator: "img",
};

class EventCentreLiteIframe extends IframeContainer {
  private readonly BASE_URL =
    "https://{OPERATOR}.apps.imgarena.com/ec-lite/latest/{PATH}" as const;

  readonly iFrameUrl: string = "";

  readonly args: RequiredDefaultProperties & EventCentreLiteConstructorArgs;

  constructor(originalArgs: EventCentreLiteConstructorArgs) {
    super({
      __debug__: !!originalArgs.__debug__,
      targetElementSelector: originalArgs.targetElementSelector,
    });

    this.args = Object.assign({}, defaultConstructorArgs, originalArgs);
    this.iFrameUrl = this.createEventCentreLiteUrl();
    this.initCommunication(this.iFrameUrl, "event-centre-lite");
  }

  private createEventCentreLiteUrl() {
    const path =
      this.args.env === "prod" ||
      this.args.env === "replay-dev" ||
      this.args.env === "demo"
        ? "full/"
        : "index.html";
    const iframeUrl = new URL(
      this.args.__iframe_url_override__?.origin ||
        this.BASE_URL.replace("{OPERATOR}", this.args.operator).replace(
          "{PATH}",
          path
        )
    );
    const params = new URLSearchParams(iframeUrl.search);

    this.appendParam("sport", params);
    this.appendParam("targetModule", params);
    this.appendParam("eventId", params);
    this.appendParam("theme", params);
    this.appendParam("language", params);
    this.appendParam("env", params);
    this.appendParam("view", params);
    this.appendParam("__replay_id__", params, "replayId");
    this.appendInitialContextParam(params);

    iframeUrl.search = params.toString();

    return iframeUrl.toString();
  }

  private appendParam(
    argName: keyof EventCentreLiteConstructorArgs,
    params: URLSearchParams,
    overrideParamName?: string
  ) {
    if (this.args[argName]) {
      const argValue = `${this.args[argName]}`;
      params.append(overrideParamName || argName, argValue);
    }
  }

  private appendInitialContextParam(params: URLSearchParams) {
    if (
      this.args.initialContext &&
      Object.keys(this.args.initialContext).length
    ) {
      params.append(
        "initialContext",
        encodeJSONParameter(this.args.initialContext)
      );
    }
  }
}

export default function eventCentreLite(
  options: EventCentreLiteConstructorArgs
): EventCentreLiteIframe {
  return new EventCentreLiteIframe(options);
}
