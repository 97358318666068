import {
  IntegrationEvent,
  IntegrationEventListener,
  MessageTopics,
} from "../../types";
import { ParentCommunication } from "../agents/ParentCommunication";
import styles from "../style.module.css";

interface IFrameContainerInterface {
  emit(topic: MessageTopics, message: IntegrationEvent): Error | void;

  on(topic: MessageTopics, callback: IntegrationEventListener): void;

  removeCallback(topic: MessageTopics): void;
}

interface Args {
  __debug__: boolean;
  targetElementSelector: string;
}

export class IframeContainer implements IFrameContainerInterface {
  debug = false;
  communications: Array<ParentCommunication> = [];
  listeners: { [topic: string]: IntegrationEventListener } = {};
  private frontRowSeatContainerElement: HTMLElement;

  constructor(args: Args) {
    this.debug = !!args.__debug__;

    //? Create parent div
    const targetElement = this.getTargetElement(args.targetElementSelector);
    this.frontRowSeatContainerElement = document.createElement("div");
    this.frontRowSeatContainerElement.className =
      styles["front-row-seat-container"];
    targetElement.appendChild(this.frontRowSeatContainerElement);
  }

  protected initCommunication(url: string, className: string) {
    const eventCentreCommunication = new ParentCommunication(
      url,
      this.frontRowSeatContainerElement,
      this.debug
    );

    eventCentreCommunication.iframe.className = styles[className];
    this.communications.push(eventCentreCommunication);

    this.addEventHandlers();
  }

  emit(topic: MessageTopics, context: IntegrationEvent): Error | void {
    this.communications.forEach((communication) => {
      communication.sendMessageToIframe({ topic, context });
    });
  }

  on(topic: MessageTopics, callback: IntegrationEventListener): void {
    this.listeners[topic] = callback;
  }

  removeCallback(topic: MessageTopics): void {
    delete this.listeners[topic];
  }

  private addEventHandlers() {
    this.communications.forEach((communication) => {
      communication.onMessage((message) => {
        //? we forward the message to the sportsbookwebsite
        if (this.listeners[message.topic]) {
          this.listeners[message.topic](message.context);
        }

        //? we forward the message to the other iframes handled by this frs.js instance (i.e betlink for instance)
        this.communications
          .filter((comm) => comm !== communication)
          .forEach((otherCommunication) => {
            otherCommunication.sendMessageToIframe(message);
          });
      });
    });
  }

  private getTargetElement(
    ...selectors: Array<string | undefined>
  ): HTMLElement {
    for (const selector of selectors) {
      if (selector) {
        const targetElement = document.querySelector<HTMLElement>(selector);
        if (targetElement) return targetElement;
      }
    }
    throw Error(
      "Failed to find target element with selector: " + selectors.join(",")
    );
  }
}
