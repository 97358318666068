import {
  ContextUpdateEvents,
  EventCentreConstructorArgs,
  InitializationOptions,
} from "../types";

export function decodeJSONParameter<
  T = ContextUpdateEvents | InitializationOptions | EventCentreConstructorArgs
>(input?: string, urlEncoded = false): T | undefined {
  if (!input) return;

  if (urlEncoded) {
    return JSON.parse(decodeURIComponent(window.atob(input)));
  }

  const decoded = window.atob(input);

  return JSON.parse(decoded);
}
