import { isValidMessageTopic } from "../../utils";
import {
  IntegrationEvent,
  IntegrationEventListener,
  MessageTopics,
} from "../../types";
import { ChildCommunication } from "../agents/ChildCommunication";
interface EventCentreClient {
  emit(topic: MessageTopics, message: IntegrationEvent): Error | void;
  on(topic: MessageTopics, callback: IntegrationEventListener): void;
  removeCallback(topic: MessageTopics): void;
  getVersion:
    | undefined // legacy
    | (() => Promise<
        { hostVersion?: string; guestVersion?: string } | undefined
      >);
}

class EventCentreClientImpl implements EventCentreClient {
  comms: ChildCommunication;
  listeners: { [topic: string]: IntegrationEventListener } = {};

  constructor(debug?: boolean) {
    this.comms = new ChildCommunication(debug);
    this.comms.onMessage((message) => {
      if (this.listeners[message.topic]) {
        this.listeners[message.topic](message.context);
      }
    });
  }

  emit = (topic: MessageTopics, context: IntegrationEvent): void => {
    isValidMessageTopic(topic);

    this.comms.sendMessageToParent({ topic, context });
  };

  on = (topic: MessageTopics, callback: IntegrationEventListener): void => {
    this.listeners[topic] = callback;
  };

  removeCallback = (topic: MessageTopics): void => {
    delete this.listeners[topic];
  };

  async getVersion() {
    return {
      guestVersion: FRSJS_VERSION,
      hostVersion: await this.comms.getVersionNumber(),
    };
  }
}

function eventCentreClient(debug?: boolean): EventCentreClient {
  return new EventCentreClientImpl(debug);
}

export default eventCentreClient;
