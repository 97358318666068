import { eventCentreClient, eventCentre, eventCenterLite } from "./lib";
import { encodeJSONParameter, decodeJSONParameter } from "./utils";
import { MessageTopics } from "./types";

const eventCentreUtils = {
  MessageTopics,
  encodeJSONParameter,
  decodeJSONParameter,
};

export * from "./types";
export { eventCentreClient, eventCentre, eventCenterLite, eventCentreUtils };

export default {
  eventCentreClient,
  eventCenterLite,
  eventCentre,
  eventCentreUtils,
};
