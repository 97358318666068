import { EventCentreConstructorArgs } from "../types";
import { encodeJSONParameter } from "../utils";

const imgArenaProdDomain = "apps.imgarena.com";

/**
 * Generates the URL for the betlink iframe
 * @param {EventCentreConstructorArgs} args - The options for the betlink iframe
 * @return {string} - The URL for the betlink iframe
 */
export function createBetlinkUrl(args: EventCentreConstructorArgs): string {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { __betlink_iframe_url_override__, ...ecProps } = args;

  //?supports wildcard version and latest version, target module is always "full" for Betlink
  let baseUrl = `https://${args.operator}.${imgArenaProdDomain}/betlink/${
    args.betlinkVersion || "latest"
  }/full/`;

  if (__betlink_iframe_url_override__) {
    baseUrl =
      __betlink_iframe_url_override__.origin +
      __betlink_iframe_url_override__.path;
  }

  //? if eventId is provided, the URL changes to https://apps.frs-dev.imgarena.dev/betlink/${args.betlinkVersion}/index.html?env=prod#/golf/markets/${eventId}/outright
  if (args.eventId) {
    baseUrl = `${baseUrl}#/golf/markets/${args.eventId}/outright`;
  }

  const iframeUrl = new URL(baseUrl);
  const params = new URLSearchParams(iframeUrl.search);

  //? betlink backend env and btec env always defaults to demo.
  const betlinkEnv = args.betlinkEnv || args.env || "demo";
  const btecEnv = args.env || "demo";
  const operatorEnv = args.operatorEnv || "prod";

  params.append("betlinkEnv", betlinkEnv);
  params.append("env", btecEnv);
  params.append("language", args.language || "en_GB");
  params.append("initialContext", encodeJSONParameter(ecProps, true));
  params.append("operatorEnv", operatorEnv);

  iframeUrl.search = params.toString();

  return iframeUrl.toString();
}
