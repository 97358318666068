export const debugLog =
  (debug?: boolean) =>
  (...args: unknown[]) => {
    if (debug) {
      // eslint-disable-next-line no-console
      console.debug.apply(null, args);
    }
  };

export const pretty = (obj: unknown) => JSON.stringify(obj, null, 2);
