import { EventCentreConstructorArgs } from "../types";

export const defaultEventCentreConstructionArgs: Partial<EventCentreConstructorArgs> =
  {
    theme: "default",
    language: "en-GB",
    targetModule: "full",
    env: "prod",
    version: "latest",
  };
